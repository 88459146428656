<template>
  <div v-if="userIsSuspended" class="d-flex flex-column justify-center align-center text-center pa-16">
      <p>Sua conta está suspensa por problemas financeiros. Entre em contato com nossa equipe pelo <strong>contato@simplificador.com.br</strong> ou pelo nosso whatsapp <a href="https://api.whatsapp.com/send/?phone=5511975386175&text=Minha%20conta%20est%C3%A1%20suspensa.%20Preciso%20de%20ajuda.&type=phone_number&app_absent=0" target="_blank">+55 11 975386175</a> para regularizar sua conta.</p>
  </div>
  <div v-else class="d-flex flex-column justify-center align-center text-center pa-16">
    <v-col cols="4">

        <p>Forma de Pagamento Atual</p>
        <div
          v-if="isBankSlip"
          class="form mb-3 d-flex justify-center align-center text-center"
        >
          <img :src="imgBoleto" />Boleto Bancário
        </div>
        <div v-else class="form d-flex justify-center align-center text-center">
          <v-icon>credit_card</v-icon> Cartão de Crédito
        </div>

    </v-col>
    <slot></slot>
  </div>

</template>

<script>
import imgBoleto from '@src/assets/images/misc/icon-pag-boleto.png'
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      imgBoleto,
    }
  },
  computed: {
    ...mapGetters({
      paymentInfo: 'paymentInfo/companyPaymentInfo',
      userIsSuspended: 'auth/userIsSuspended'
    }),
    isBankSlip() {
      return this.paymentInfo.paymentMethod.name === 'BANK_SLIP'
    },
  },
}
</script>

<style scoped>
.form {
  align-items: center;
  height: 83px;
  border: 1px solid #b9b9b8;
  border-radius: 6px;
}
.form i {
  margin-left: 15px;
  font-size: 4em;
}
.form img {
  width: 70px;
  margin-right: 10px;
}
</style>
